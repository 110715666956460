<template>
  <octo-table
    show-filters
    :action="endpoints.DATATABLES.sentToLawyer"
    :fields="fields"
    :filters="filters"
    :name="name"
    enable-export
    @onResetFilters="filters = {}"
  >

    <template v-slot:name="data">
      {{ data.row.surname}} {{data.row.name }}
    </template>

    <template v-slot:amount="data">
      {{ data.row.total_amount | euro }}
    </template>

    <template v-slot:paid="data">
      {{ data.row.paid | euro }}
    </template>

    <template v-slot:to_pay="data">
      {{ data.row.to_pay | euro }}
    </template>

    <template v-slot:date_out="data">
      {{ data.row.date_out | date }}
    </template>

    <template v-slot:competence_date="data">
      {{ data.row.competence_date | date }}
    </template>

    <template v-slot:sent_to_lawyer_date="data">
      {{ data.row.lawyer_date | date }}
    </template>

    <template v-slot:courses="data">
      <div class="small text-truncate">{{ data.row.courses }}</div>
    </template>

    <template v-slot:sale="data">
      <div class="small text-uppercase">
        {{ users[data.row.user_id] | optional('lastname') }}
        {{ users[data.row.user_id] | optional('firstname') }}
      </div>
    </template>

    <template v-slot:accounting_status="data">
      <badge :type="dealAccountingStatuses[data.row.accounting_status].color" class="text-uppercase">
        {{ $t('deal.accounting_statuses.' + data.row.accounting_status) }}
      </badge>
    </template>

    <template v-slot:financing_status="data">
      <badge v-if="data.row.financing_status" :type="dealFinancialStatuses[data.row.financing_status].color"
             class="text-uppercase">
        {{ $t('deal.financing_statuses.' + data.row.financing_status) }}
      </badge>
    </template>

    <template v-slot:deal_status="data">
      <badge  v-if="data.row.deal_status"
              :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.deal_status)}" class="text-uppercase">
        {{ $t('deal.status.' + data.row.deal_status) }}
      </badge>
    </template>

    <template v-slot:student_status="data">
      <badge v-if="data.row.student_status" :type="data.row.student_status | studentStatusColor" class="text-uppercase">
        {{ $t('didactics.student_status.' + data.row.student_status) }}
      </badge>
    </template>

    <template v-slot:status="data">
      <badge v-if="data.row.status" :type="paymentStatusConst[data.row.status].color" class="text-uppercase">
        {{ $t('deal.payment_statuses.' + data.row.status) }}
      </badge>
    </template>


    <template slot="custom-filters">
      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.sent_to_lawyer_date')">
          <el-date-picker
            v-model="filters.dateSentToLawyerRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="pickerOptions"
          />
        </base-input>
      </div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip :content="$t('deal.show_deal')" :open-delay="300" placement="top">
          <base-button class="mx-1"
                       size="sm"
                       link
                       icon
                       @click="$router.push({
                         name: 'post_selling.show',
                         params: {id: data.row.id}
                       })">
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>
  </octo-table>
</template>


<script>
import OctoTable from "@/components/octo-table/OctoTable.vue";
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";
import {Select, Option, DatePicker} from "element-ui";
import PaymentStatusConst from "../../Deals/resources/paymentStatusConst";
import PaymentReasonConst from "../../Deals/resources/paymentReasonConst";
import Badge from "@/components/Badge.vue";
import LabelThemeComponent from "@/components/LabelThemeComponent.vue";
import OctoIcon from "../../../components/octo-icon/OctoIcon.vue";
import DealPaymentTypes from "@/pages/Deals/resources/dealPaymentTypes";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";
import DealFinancialStatus from "@/pages/Deals/resources/dealFinancialStatus";

export default {
  name: "SentToLawyerDatatable",
  components: {

    OctoIcon,
    LabelThemeComponent,
    Badge,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      name: 'SentToLawyerDatatable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'name', label: 'name', slot: true, align: 'left', fixed:true, width: 250},
        {prop: 'date_out', label: 'date_out', slot: true, align: 'center',width:150 },
        {prop: 'competence_date', label: 'competence_date', slot: true, align: 'center',width:150 },
        {prop: 'sent_to_lawyer_date', label: 'sent_to_lawyer_date', slot: true, align: 'center',width:180 },
        {prop: 'deal_status', label: 'deal_status', slot: true, align: 'center', minWidth: 180},
        {prop: 'accounting_status', label: 'accounting_status', slot: true, align: 'center', width:150},
        {prop: 'financing_status', label: 'financing_status', slot: true, align: 'center', width:200},
        {prop: 'paid', label: 'paid', slot: true, align: 'center', width:150},
        {prop: 'to_pay', label: 'to_pay', slot: true, align: 'center', width:150},
        {prop: 'amount', label: 'amount', slot: true, align: 'center', width:120},
        {prop: 'sale', label: 'sale', slot: true, align: 'left', width:180},
        {prop: 'courses', label: 'courses', slot: true, align: 'left', width:400},
        {prop: 'actions', label: 'actions', width: 100, slot: true, fixed:'right'},
      ],
      paymentStatusConst: PaymentStatusConst,
      paymentReasonConst: PaymentReasonConst,
      paymentOptions: this.$_.map(DealPaymentTypes, (item) => item.value),
      dealAccountingStatuses: DealAccountingStatus,
      schedulerService: schedulerService,
      dealFinancialStatuses: DealFinancialStatus,
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
      users: 'common/users',
    }),
    pickerOptions() {
      return {firstDayOfWeek: 1};
    }
  }
}
</script>

<style scoped>

</style>
