<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.debt_collection_to_send') }}</div>
              </div>
            </template>
            <octo-header-button
              @onSendToLawyer="sendToLawyer"
              @onSendToLawyerPreview="sendToLawyerPreview"
              :buttons="[
                {label: 'preview', onClick: 'onSendToLawyerPreview', confirmRequired: true},
                {label: 'send', onClick: 'onSendToLawyer', confirmRequired: true},
                ]"
            />
            <card body-classes="standard-card-body" class="mr-2 shadow">
              <el-table
                stripe
                @selection-change="handleSelectionChange"
                :data="items">
                <el-table-column type="selection" width="55"/>
                <el-table-column align="left" :label="$t('fields.name')" :width="200" fixed>
                  <div slot-scope="{ row }" class="text-truncate text-uppercase">
                    {{ row.surname }} {{ row.name }}
                  </div>
                </el-table-column>

                <el-table-column align="center" :label="$t('deal.date_end_contract')" :width="220">
                  <div slot-scope="{ row }">
                    {{ row.date_out | date }}
                  </div>
                </el-table-column>

                <el-table-column align="center" :label="$t('datatable.competence_date')" :width="150">
                  <div slot-scope="{ row }">
                    {{ row.competence_date | date }}
                  </div>
                </el-table-column>
                <el-table-column align="center" :label="$t('datatable.deal_status')" :width="200">
                  <div slot-scope="{ row }">
                    <badge v-if="row.deal_status"
                           :style="{backgroundColor: schedulerService.getDealStatusColor(row.deal_status)}"
                           class="text-uppercase">
                      {{ $t('deal.status.' + row.deal_status) }}
                    </badge>
                  </div>
                </el-table-column>
                <el-table-column align="center" :label="$t('datatable.accounting_status')" :width="180">
                  <div slot-scope="{ row }">
                    <badge v-if="row.accounting_status" :type="dealAccountingStatuses[row.accounting_status].color"
                           class="text-uppercase">
                      {{ $t('deal.accounting_statuses.' + row.accounting_status) }}
                    </badge>
                  </div>
                </el-table-column>
                <el-table-column align="left" :label="$t('datatable.financing_status')" :width="200">
                  <div slot-scope="{ row }">
                    <badge v-if="row.financing_status" :type="dealFinancialStatuses[row.financing_status].color"
                           class="text-uppercase">
                      {{ $t('deal.financing_statuses.' + row.financing_status) }}
                    </badge>

                  </div>
                </el-table-column>
                <el-table-column align="center" :label="$t('common.paid')" :width="150">
                  <div slot-scope="{ row }">
                    {{ row.paid | euro }}
                  </div>
                </el-table-column>
                <el-table-column align="center" :label="$t('common.to_pay')" :width="150">
                  <div slot-scope="{ row }">
                    {{ row.to_pay | euro }}
                  </div>
                </el-table-column>
                <el-table-column align="center" :label="$t('fields.amount')" :width="150">
                  <div slot-scope="{ row }">
                    {{ row.total_amount | euro }}
                  </div>
                </el-table-column>

                <el-table-column align="center" :label="$t('fields.sale')" :width="150">
                  <div slot-scope="{ row }">
                    <div class="small text-uppercase">
                      {{ users[row.user_id] | optional('lastname') }}
                      {{ users[row.user_id] | optional('firstname') }}
                    </div>
                  </div>
                </el-table-column>

                <el-table-column align="left" :label="$t('fields.courses')" :width="400">
                  <div slot-scope="{ row }" class="small text-truncate text-uppercase">
                    {{ row.courses }}
                  </div>
                </el-table-column>

                <el-table-column align="right" width="120" fixed="right">
                  <div slot-scope="{ row }">
                    <base-button class="mx-1" size="sm" icon link
                                 @click="$router.push({name: 'post_selling.show', params: {id :  row.id}})">
                      <octo-icon icon="user"/>
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </card>
          </tab-pane>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.recap_sending') }}</div>
              </div>
            </template>
            <sent-to-lawyer-datatable/>
          </tab-pane>
        </tabs>

      </div>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge.vue";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";
import DealFinancialStatus from "@/pages/Deals/resources/dealFinancialStatus";
import OctoIcon from "@/components/octo-icon/OctoIcon.vue";
import {TabPane, Tabs} from "@/components";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton.vue";
import calcTextareaHeight from "element-ui/packages/input/src/calcTextareaHeight";
import {mapActions, mapGetters} from "vuex";
import SentToLawyerDatatable from "@/pages/PostSelling/datatables/SentToLawyerDatatable.vue";

export default {
  name: "IndexDebtCollectionPage.vue",
  components: {
    SentToLawyerDatatable,
    OctoHeaderButton,
    OctoIcon,
    Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Tabs,
    TabPane,
  },
  data() {
    return {
      items: [],
      selectedItems: [],
      dealStatuses: DealStatuses,
      dealAccountingStatuses: DealAccountingStatus,
      schedulerService: schedulerService,
      dealFinancialStatuses: DealFinancialStatus,
    }
  },
  async beforeMount() {
    await this.loadItems();
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
    })
  },
  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile'
    }),
    handleSelectionChange: function (items) {
      this.selectedItems = this.$_.map(items, (item) => item.id);
    },
    loadItems: async function () {
      try {
        this.$fullLoading.show();
        const resp = await this.$api.get(endpoints.POST_SELLING_DEBT_COLLECTION_INDEX);
        this.items = resp?.data || [];
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
    sendToLawyerPreview: async function () {
      await this.sendToLawyer(true)
    },
    sendToLawyer: async function (preview) {
      try {
        if(this.selectedItems.length > 0) {
          this.$fullLoading.show();
          const data = {
            url: endpoints.POST_SELLING_DEBT_COLLECTION_SEND_TO_LAWYER,
            filename: 'send_to_layer_' + this.$moment().format('YYYY-MM-DD') + '.xlsx',
            method: 'post',
            data: {
              deal_ids : this.selectedItems,
              preview: preview
            },
          }
          await this.downloadFile(data)
          this.$fullLoading.hide();
          await this.loadItems();

        } else {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        }
      } catch (e) {

        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    }
  }
}
</script>

<style scoped>

</style>
