var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('tabs',{attrs:{"centered":"","square":""}},[_c('tab-pane',[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"text-uppercase m-3"},[_vm._v(_vm._s(_vm.$t('deal.debt_collection_to_send')))])])]),_c('octo-header-button',{attrs:{"buttons":[
              {label: 'preview', onClick: 'onSendToLawyerPreview', confirmRequired: true},
              {label: 'send', onClick: 'onSendToLawyer', confirmRequired: true} ]},on:{"onSendToLawyer":_vm.sendToLawyer,"onSendToLawyerPreview":_vm.sendToLawyerPreview}}),_c('card',{staticClass:"mr-2 shadow",attrs:{"body-classes":"standard-card-body"}},[_c('el-table',{attrs:{"stripe":"","data":_vm.items},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.name'),"width":200,"fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{staticClass:"text-truncate text-uppercase"},[_vm._v(" "+_vm._s(row.surname)+" "+_vm._s(row.name)+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('deal.date_end_contract'),"width":220},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.date_out))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('datatable.competence_date'),"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.competence_date))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('datatable.deal_status'),"width":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[(row.deal_status)?_c('badge',{staticClass:"text-uppercase",style:({backgroundColor: _vm.schedulerService.getDealStatusColor(row.deal_status)})},[_vm._v(" "+_vm._s(_vm.$t('deal.status.' + row.deal_status))+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('datatable.accounting_status'),"width":180},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[(row.accounting_status)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm.dealAccountingStatuses[row.accounting_status].color}},[_vm._v(" "+_vm._s(_vm.$t('deal.accounting_statuses.' + row.accounting_status))+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('datatable.financing_status'),"width":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[(row.financing_status)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm.dealFinancialStatuses[row.financing_status].color}},[_vm._v(" "+_vm._s(_vm.$t('deal.financing_statuses.' + row.financing_status))+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('common.paid'),"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("euro")(row.paid))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('common.to_pay'),"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("euro")(row.to_pay))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.amount'),"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("euro")(row.total_amount))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.sale'),"width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"small text-uppercase"},[_vm._v(" "+_vm._s(_vm._f("optional")(_vm.users[row.user_id],'lastname'))+" "+_vm._s(_vm._f("optional")(_vm.users[row.user_id],'firstname'))+" ")])])}}])}),_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.courses'),"width":400},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{staticClass:"small text-truncate text-uppercase"},[_vm._v(" "+_vm._s(row.courses)+" ")])}}])}),_c('el-table-column',{attrs:{"align":"right","width":"120","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{},[_c('base-button',{staticClass:"mx-1",attrs:{"size":"sm","icon":"","link":""},on:{"click":function($event){return _vm.$router.push({name: 'post_selling.show', params: {id :  row.id}})}}},[_c('octo-icon',{attrs:{"icon":"user"}})],1)],1)}}])})],1)],1)],2),_c('tab-pane',[_c('template',{slot:"label"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"text-uppercase m-3"},[_vm._v(_vm._s(_vm.$t('deal.recap_sending')))])])]),_c('sent-to-lawyer-datatable')],2)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }